import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Participants`}</h1>
    <ul>
      <li parentName="ul">{`Respondents in a `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`.`}</li>
      <li parentName="ul">{`They have the ability to `}<a parentName="li" {...{
          "href": "/agree",
          "title": "agree"
        }}>{`agree`}</a>{`, `}<a parentName="li" {...{
          "href": "/disagree",
          "title": "disagree"
        }}>{`disagree`}</a>{`, and `}<a parentName="li" {...{
          "href": "/pass",
          "title": "pass"
        }}>{`pass`}</a>{` on `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` submitted by others.`}</li>
      <li parentName="ul">{`They have the ability to write `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` <140 characters to be voted on by all other `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{`.`}</li>
      <li parentName="ul">{`They do not need to `}<a parentName="li" {...{
          "href": "/vote",
          "title": "vote"
        }}>{`vote`}</a>{` on every `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` to be counted or to be understood in real-time and subsequent analysis.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      